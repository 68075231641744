.scrolldown-wrapper {
  left: 52%;
  position: absolute;
  text-align: center;
  bottom: 0;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.scrolldown {
  border: 2px solid #ffffff;
  border-radius: 30px;
  height: 46px;
  margin: 0 auto 8px;
  text-align: center;
  width: 30px;
}

.scrolldown-p1,
.scrolldown-p2 {
  animation-duration: 1.5s;
  animation-name: scrolldown;
  animation-iteration-count: infinite;
  fill: #ffffff;
}

.scrolldown-p2 {
  animation-delay: 0.75s;
}

@keyframes scrolldown {
  0% {
    opacity: 0;
    transform: translate(0, -8px);
  }
  50% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 8px);
  }
}

.scrolldown-text {
  color: #fff;
  margin-top: 10px;
}

/* Small screens */
@media only screen and (max-width: 599px) {
  .scrolldown-wrapper {
    left: 50%;
    bottom: 50px;
    z-index: 100000;
  }
}
