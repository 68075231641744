@font-face {
  font-family: "Paloseco";
  src: url("../utils/Paloseco-Medium.ttf") format("truetype");
}

.navbar {
  z-index: 100000000;
  position: fixed;
  -webkit-transition: width 300ms ease;
  transition: width 300ms ease;
  overflow: clip;
  border: 4px solid black;
  background: url('data:image/svg+xml;utf8,<svg width="100" height="100" transform="rotate(25)" opacity="0.3" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><g  fill="%23250E17"><circle cx="25" cy="25" r="12.5"/><circle cx="75" cy="75" r="12.5"/><circle cx="75" cy="25" r="12.5"/><circle cx="25" cy="75" r="12.5"/></g></svg>'),
    rgb(88, 88, 88);
  background-size: 25px, 100%;
  font-family: Paloseco, sans-serif;
  letter-spacing: 0.1em;
  box-shadow: 6px 0 2px rgba(0, 0, 0, 0.35);
}

.navbar::before {
  content: "";
  border: 2px solid black;
  display: block;
  width: 98%;
  height: 97%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
  transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
  border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.nav-item {
  width: 100%;
  cursor: pointer;
}

.nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 5rem;
  color: var(--text-primary);
  text-decoration: none;
  -webkit-filter: opacity(0.7);
  filter: opacity(0.7);
  -webkit-transition: var(--transiti99on-speed);
  transition: var(--transiti99on-speed);
}

.nav-link:hover {
  -webkit-filter: grayscale(0%) opacity(1);
  filter: grayscale(0%) opacity(1);
  background: rgba(0, 0, 0, 0.555);
  color: var(--text-secondary);
}

.link-text {
  display: none;
  margin-left: 1rem;
}

.nav-link svg {
  width: 2rem;
  min-width: 2rem;
  margin: 0 1rem;
}

.fa-primary {
  color: #ffb703;
}

.fa-secondary {
  color: #ffffff;
}

.fa-primary,
.fa-secondary {
  -webkit-transition: var(--transition-speed);
  transition: var(--transition-speed);
}

.fa-primary-chg {
  display: none;
}

.logo {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--text-secondary);
  background: rgba(0, 0, 0, 0.475);
  font-size: 2rem;
  letter-spacing: 0.3ch;
  width: 100%;
}

.logo svg {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: var(--transition-speed);
  transition: var(--transition-speed);
}

.logo-text {
  display: inline;
  position: absolute;
  left: -999px;
  -webkit-transition: var(--transition-speed);
  transition: var(--transition-speed);
  margin-left: 2rem;
}

.navbar:hover .logo svg {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.social {
  position: absolute;
  bottom: 1rem;
}

.codepen-li {
  margin-bottom: 9rem;
}

.github-li {
  margin-bottom: 4.5rem;
}

/* Small screens */
@media only screen and (max-width: 599px) {
  .navbar {
    bottom: 0;
    width: 100vw;
    height: 5rem;
  }

  .logo {
    display: none;
  }

  .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .nav-link {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .nav-link:hover {
    background: none;
  }

  main {
    margin: 0;
  }

  .social {
    display: none;
  }
}

/* Large screens */
@media only screen and (min-width: 600px) {
  .navbar {
    top: 0;
    width: 5rem;
    height: 100vh;
  }

  .navbar:hover {
    width: 16rem;
  }

  .navbar:hover .link-text {
    display: inline;
  }

  .navbar:hover .logo svg {
    margin-left: 11rem;
  }

  .navbar:hover .logo-text {
    left: 0px;
  }
}
