.bg-scene5 {
  background-image: url("../images/backgrounds/bg-scene5.jpg");
}

.img-scene5 {
  z-index: 15;
  content: url("../images/badGuy/hands-flip.png");
  position: fixed;
}

.base {
  background-color: black;
  position: fixed;
  width: 100vw;
  bottom: 0;
}

.base::before {
  content: "";
  position: absolute;
  background: -webkit-linear-gradient(bottom, black, transparent);
  background: linear-gradient(to top, black, transparent);
}

.txt-scene5 {
  background-image: url("../images/badGuy/laptop.png");
}

.messages-scene5 {
  font-family: monospace;
  position: relative;
  color: green;
  text-align: left !important;
}

/* Ultra Super Extra small devices (phones, 399px and down) */
@media only screen and (max-width: 399px) {
  .messages-scene5 {
    top: 15px;
    font-size: 0.6rem;
  }

  .base {
    height: 30vh;
  }

  .base::before {
    bottom: 30vh;
    width: 100%;
    height: 50px;
  }
}

/* Super Extra small devices (phones, between 400px and 499px) */
@media only screen and (min-width: 400px) and (max-width: 499px) {
  .messages-scene5 {
    top: 1vh;
    font-size: 0.7rem;
  }

  .base {
    height: 30vh;
  }

  .base::before {
    bottom: 30vh;
    width: 100%;
    height: 50px;
  }
}

/* Extra small devices (phones, 500px and up) */
@media only screen and (min-width: 500px) {
  .messages-scene5 {
    top: 90px;
    font-size: 0.9rem;
  }

  .base {
    height: 30vh;
  }

  .base::before {
    bottom: 30vh;
    width: 100%;
    height: 50px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .messages-scene5 {
    top: 40px;
    font-size: 1rem;
  }

  .base {
    height: 20vh;
  }

  .base::before {
    bottom: 20vh;
    width: 100%;
    height: 50px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .messages-scene5 {
    top: 120px;
    font-size: 1.3rem;
  }

  .base {
    height: 20vh;
  }

  .base::before {
    bottom: 20vh;
    width: 100%;
    height: 50px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .messages-scene5 {
    top: 40px;
    font-size: 1.1rem;
  }

  .base {
    height: 20vh;
  }

  .base::before {
    bottom: 20vh;
    width: 100%;
    height: 50px;
  }
}

/* IPad Pro */
@media only screen and (min-width: 1024px) and (min-height: 1366px) {
  .messages-scene5 {
    top: 70px;
    font-size: 1.3rem;
  }

  .base {
    height: 25vh;
  }

  .base::before {
    bottom: 25vh;
    width: 100%;
    height: 50px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .messages-scene5 {
    top: 30px;
    font-size: 2.5vh;
  }

  .base {
    height: 20vh;
  }

  .base::before {
    bottom: 20vh;
    width: 100%;
    height: 50px;
  }
}

/* Super Extra large devices (large laptops and desktops, 1600px and up) */
@media only screen and (min-width: 1600px) {
  .messages-scene5 {
    top: 30px;
    font-size: 2.5vh;
  }

  .base {
    height: 20vh;
  }

  .base::before {
    bottom: 20vh;
    width: 100%;
    height: 50px;
  }
}

/* Iphone 6/7/8 Plus Landscape */
@media only screen and (max-width: 736px) and (max-height: 414px) and (orientation: landscape) {
  .messages-scene5 {
    top: -40px;
    font-size: 0.8rem;
  }

  .base {
    height: 0;
  }

  .base::before {
    height: 0;
  }
}
