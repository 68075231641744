.bg-scene2 {
  background-image: url("../images/backgrounds/bg-scene2.jpg");
}

.img-scene2 {
  content: url("../images/me/22.png");
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.img-scene2-chg {
  display: none;
}

.img2-scene2 {
  content: url("../images/badGuy/25.png");
}

.img2-scene2-chg {
  display: none;
}

.img3-scene2 {
  content: url("../images/me/03.png");
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.img3-scene2-chg {
  display: none;
}

.txt-scene2 {
  background-image: url("../images/speechBubbles/speech-bubble1.png");
}

.txt-scene2-chg {
  background-image: url("../images/speechBubbles/speech-bubble4.png");
}

.first-speech-scene2,
.second-speech-scene2,
.third-speech-scene2,
.fourth-speech-scene2,
.fifth-speech-scene2 {
  position: relative;
}

.third-speech-scene2,
.fourth-speech-scene2 {
  text-shadow: 2px 2px 6px #ff0000;
  -webkit-animation: color-change 5s linear infinite;
  animation: color-change 5s linear infinite;
}

/* Ultra Super Extra small devices (phones, 399px and down) */
@media only screen and (max-width: 399px) {
  .first-speech-scene2 {
    top: 25px;
    font-size: 1.1rem;
  }

  .second-speech-scene2 {
    top: 35px;
    font-size: 1rem;
  }

  .third-speech-scene2 {
    top: 55px;
    left: -15px;
    font-size: 2.7rem;
  }

  .fourth-speech-scene2 {
    top: 60px;
    left: -15px;
    font-size: 1.3rem;
  }

  .fifth-speech-scene2 {
    top: 35px;
    font-size: 2.5rem;
  }
}

/* Super Extra small devices (phones, between 400px and 499px) */
@media only screen and (min-width: 400px) and (max-width: 499px) {
  .first-speech-scene2 {
    top: 25px;
    font-size: 1.5rem;
  }

  .second-speech-scene2 {
    top: 45px;
    font-size: 1.2rem;
  }

  .third-speech-scene2 {
    top: 70px;
    left: -15px;
    font-size: 4rem;
  }

  .fourth-speech-scene2 {
    top: 77px;
    left: -15px;
    font-size: 1.7rem;
  }

  .fifth-speech-scene2 {
    top: 40px;
    font-size: 3rem;
  }
}

/* Extra small devices (phones, 500px and up) */
@media only screen and (min-width: 500px) {
  .first-speech-scene2 {
    top: 35px;
    font-size: 1.7rem;
  }

  .second-speech-scene2 {
    top: 55px;
    font-size: 1.5rem;
  }

  .third-speech-scene2 {
    top: 70px;
    left: -15px;
    font-size: 4rem;
  }

  .fourth-speech-scene2 {
    top: 80px;
    left: -15px;
    font-size: 1.7rem;
  }

  .fifth-speech-scene2 {
    top: 45px;
    font-size: 4rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .first-speech-scene2 {
    top: 60px;
    font-size: 1.7rem;
  }

  .second-speech-scene2 {
    top: 80px;
    font-size: 1.5rem;
  }

  .third-speech-scene2 {
    top: 100px;
    left: -15px;
    font-size: 4rem;
  }

  .fourth-speech-scene2 {
    top: 105px;
    left: -15px;
    font-size: 2rem;
  }

  .fifth-speech-scene2 {
    top: 75px;
    font-size: 4rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .first-speech-scene2 {
    top: 50px;
    font-size: 2rem;
  }

  .second-speech-scene2 {
    top: 75px;
    font-size: 1.8rem;
  }

  .third-speech-scene2 {
    top: 100px;
    font-size: 5.5rem;
  }

  .fourth-speech-scene2 {
    top: 120px;
    left: -20px;
    font-size: 2.2rem;
  }

  .fifth-speech-scene2 {
    top: 70px;
    font-size: 4rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .first-speech-scene2 {
    top: 60px;
    font-size: 1.7rem;
  }

  .second-speech-scene2 {
    top: 80px;
    font-size: 1.6rem;
  }

  .third-speech-scene2 {
    top: 80px;
    left: -15px;
    font-size: 4rem;
  }

  .fourth-speech-scene2 {
    top: 90px;
    left: -20px;
    font-size: 1.7rem;
  }

  .fifth-speech-scene2 {
    top: 65px;
    font-size: 4.5rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .first-speech-scene2 {
    top: 55px;
    font-size: 2rem;
  }

  .second-speech-scene2 {
    top: 85px;
    font-size: 1.8rem;
  }

  .third-speech-scene2 {
    top: 90px;
    left: -15px;
    font-size: 4.5rem;
  }

  .fourth-speech-scene2 {
    top: 100px;
    left: -20px;
    font-size: 2rem;
  }

  .fifth-speech-scene2 {
    top: 70px;
    font-size: 4.5rem;
  }
}

/* Super Extra large devices (large laptops and desktops, 1600px and up) */
@media only screen and (min-width: 1600px) {
  .first-speech-scene2 {
    top: 78px;
    font-size: 2.2rem;
  }

  .second-speech-scene2 {
    top: 105px;
    font-size: 2rem;
  }

  .third-speech-scene2 {
    top: 120px;
    left: -15px;
    font-size: 5rem;
  }

  .fourth-speech-scene2 {
    top: 125px;
    left: -20px;
    font-size: 2.5rem;
  }

  .fifth-speech-scene2 {
    top: 95px;
    font-size: 4.7rem;
  }
}

/* Iphone 6/7/8 Plus Landscape */
@media only screen and (max-width: 736px) and (max-height: 414px) and (orientation: landscape) {
  .first-speech-scene2 {
    top: -45px;
    font-size: 1.3rem;
  }

  .second-speech-scene2 {
    top: -35px;
    font-size: 1.1rem;
  }

  .third-speech-scene2 {
    top: 0;
    left: -10px;
    font-size: 3rem;
  }

  .fourth-speech-scene2 {
    top: -13px;
    left: -14px;
    font-size: 1.3rem;
  }

  .fifth-speech-scene2 {
    top: -20px;
    font-size: 3rem;
  }
}
