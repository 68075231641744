.bg-scene8 {
  background-image: url("../images/backgrounds/bg-scene8.jpg");
}

.img-scene8 {
  content: url("../images/me/15.png");
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.img-scene8-chg {
  display: none;
}

.img2-scene8 {
  content: url("../images/me/24.png");
}

.img2-scene8-chg {
  display: none;
}

.txt-scene8 {
  background-image: url("../images/speechBubbles/speech-bubble1.png");
}

.first-speech-scene8,
.second-speech-scene8 {
  position: relative;
}

/* Ultra Super Extra small devices (phones, 399px and down) */
@media only screen and (max-width: 399px) {
  .first-speech-scene8 {
    top: 27px;
    font-size: 1.2rem;
  }

  .second-speech-scene8 {
    top: 18px;
    font-size: 1.1rem;
  }
}

/* Super Extra small devices (phones, between 400px and 499px) */
@media only screen and (min-width: 400px) and (max-width: 499px) {
  .first-speech-scene8 {
    top: 45px;
    font-size: 1.5rem;
  }

  .second-speech-scene8 {
    top: 30px;
    font-size: 1.5rem;
  }
}

/* Extra small devices (phones, 500px and up) */
@media only screen and (min-width: 500px) {
  .first-speech-scene8 {
    top: 60px;
    font-size: 1.7rem;
  }

  .second-speech-scene8 {
    top: 40px;
    font-size: 1.7rem;
  }
}

/* Extra small devices (phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .first-speech-scene8 {
    top: 75px;
    font-size: 1.8rem;
  }

  .second-speech-scene8 {
    top: 60px;
    font-size: 1.7rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .first-speech-scene8 {
    top: 85px;
    font-size: 2.2rem;
  }

  .second-speech-scene8 {
    top: 65px;
    font-size: 2rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .first-speech-scene8 {
    top: 75px;
    font-size: 2rem;
  }

  .second-speech-scene8 {
    top: 60px;
    font-size: 1.7rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .first-speech-scene8 {
    top: 75px;
    font-size: 2.3rem;
  }

  .second-speech-scene8 {
    top: 50px;
    font-size: 2rem;
  }
}

/* Super Extra large devices (large laptops and desktops, 1600px and up) */
@media only screen and (min-width: 1600px) {
  .first-speech-scene8 {
    top: 100px;
    font-size: 2.3rem;
  }

  .second-speech-scene8 {
    top: 80px;
    font-size: 2rem;
  }
}

/* Iphone 6/7/8 Plus Landscape */
@media only screen and (max-width: 736px) and (max-height: 414px) and (orientation: landscape) {
  .first-speech-scene8 {
    top: -10px;
    font-size: 1.7rem;
  }

  .second-speech-scene8 {
    top: -20px;
    font-size: 1.5rem;
  }
}
